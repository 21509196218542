import React from 'react';
import { withUnpublishedPreview } from 'gatsby-source-prismic';

import Article from '../templates/Article';
import Album from '../templates/Album';
import Event from '../templates/Event';
import Seo from '../components/Seo';

const NotFoundPage = () => (
  <div>
    <h1 className="page-header">Page Not Found</h1>
    <div className="page-content">
      <p>Sorry, that page doesn't exist.</p>
    </div>
    <Seo
      slug="404"
      title="Page Not Found"
      description="Sorry, that page doesn't exist."
    />
  </div>
);

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    article: Article,
    album: Album,
    event: Event,
  },
})
