import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { withPreview } from 'gatsby-source-prismic';

import Seo from '../components/Seo';
import SocialShare from '../components/SocialShare';

const Article = ({ data }) => {
  const content = data.prismicArticle.data;
  const { title } = content;

  let image = '';
  image = content.image.url ? content.image.url : '';

  return (
    <div>
      <h1 className="page-header">
        <Link to="/news">News & Updates</Link>
      </h1>
      <div
        data-aos="fade-in"
        data-aos-delay="150"
        className="page-content"
      >
        <article className="node article">
          <header className="clearfix">
            <time dateTime={content.date_alt} className="bumper alert">{content.date}</time>
            <h1 className="title">{title}</h1>
          </header>
          <SocialShare
            title={title}
            url={data.prismicArticle.url}
          />
          {content.image.fluid
            && (
              <figure className="content">
                <Image
                  sizes={content.image.fluid}
                />
              </figure>
            )
          }
          <span
            className="content"
            dangerouslySetInnerHTML={{ __html: content.content.html }}
          />
        </article>
      </div>
      <Seo
        slug={data.prismicArticle.url}
        title={`News & Updates: ${title}`}
        description={content.content.text}
        image={image}
      />
    </div>
  );
};

Article.propTypes = {
  data: PropTypes.object,
};

export default withPreview(Article);

export const query = graphql`
  query (
    $prismicId: ID!
  ) {
    prismicArticle(
      prismicId: {
        eq: $prismicId
      }
    ) {
      url
      prismicId
      data {
        title
        date(formatString: "MMMM D, YYYY")
        date_alt: date(
          formatString: "YYYY-MM-DD"
        )
        content {
          html
          text
        }
        image {
          url
          fluid(
            maxWidth: 970
          ) {
            ...GatsbyPrismicImageFluid_withWebp
          }
        }
      }
    }
  }
`;
